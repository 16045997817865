/* eslint-disable no-useless-escape */
// This file is to store some customized validation functions

/**
 * Check if username is valid email or phone number format
 */
export const isValidUsername = (username) => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // phone number must have plus sign and at least 10 digits
  const phoneRegex = /^\+?[0-9]{10,14}$/;
  return emailRegex.test(username) || phoneRegex.test(username);
};

export const isValidPassword = (password) => {
  const lowercaseRegEx = /.*[a-z]+.*/;
  const uppercaseRegEx = /.*[A-Z]+.*/;
  const numberRegEx = /.*[0-9]+.*/;
  const specialRegEx = /.*[!&^%$#@()/_*+-:;]+.*/;
  return (
    lowercaseRegEx.test(password) &&
    uppercaseRegEx.test(password) &&
    numberRegEx.test(password) &&
    specialRegEx.test(password) &&
    password.length >= 8
  );
};

export const isValidImageFormat = (file) => {
  if (file && file instanceof File) {
    const fileType = file.type ? file.type : "";
    return fileType == "image/jpeg" || fileType == "image/png";
  }
  return false;
};
