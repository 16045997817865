<template>
  <layout :showTopBanner="false" :showSidebar="false">
    <app-header
      :leftLogoPersist="true"
      :showMobileMenuButton="false"
    ></app-header>
    <forgot-password-verification-form />
  </layout>
</template>

<script>
import Header from "../app/components/headers/DefaultHeader";
import DefaultLayout from "../app/components/layouts/DefaultLayout";
import ForgotPasswordVerificationForm from "./components/ForgotPasswordVerificationForm.vue";

export default {
  components: {
    appHeader: Header,
    layout: DefaultLayout,
    "forgot-password-verification-form": ForgotPasswordVerificationForm,
  },
  metaInfo: {
    title: "Reset Password",
  },
};
</script>
