<template>
  <div>
    <form class="container" v-if="!this.resetDone" @submit.prevent="submitted">
      <div class="form-title">
        <h1>Reset password</h1>
        <p>
          Check yor email or phone number and fill in the form below to reset
          your password.
        </p>
      </div>
      <div class="form-item">
        <v-text-field
          type="text"
          label="Email or Phone number"
          :hint="usernameHint"
          v-model.trim="username"
          :error-messages="usernameInvalid"
        />
      </div>

      <div class="form-item">
        <v-text-field
          type="text"
          label="Verification code"
          hint="Enter the code we sent to your email."
          v-model.trim="code"
          :error-messages="codeInvalid"
        />
      </div>
      <div class="form-item">
        <v-text-field
          name="password"
          label="New password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          v-model="newpassword"
          hint="At least 8 characters with uppercase, number and symbol characters."
          @click:append="showPassword = !showPassword"
          :error-messages="passwordInvalid"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
        />
      </div>
      <div class="submit-button">
        <inqli-button width="100%" :isProcess="isProcessing" type="submit"
          >Submit</inqli-button
        >
      </div>
      <div v-if="this.error" class="error-message">Error: {{ this.error }}</div>
      <router-link to="/forgot-password" class="form-link"
        >Request new code</router-link
      >
    </form>
    <div v-else class="container">
      <div class="message">Password has been reset successfully!</div>
    </div>
  </div>
</template>

<script>
import Button from "../../app/components/buttons/Button";
import { required } from "vuelidate/lib/validators";
import {
  isValidUsername,
  isValidPassword,
} from "../../app/utils/validation-helper";
import { Auth } from "aws-amplify";

export default {
  components: {
    "inqli-button": Button,
  },
  metaInfo: {
    title: "Reset Password",
  },
  data() {
    return {
      username: this.$route.query.username || "",
      newpassword: "",
      code: "",
      resetDone: false,
      showPassword: false,
      isProcessing: false,
      usernameInvalid: "",
      codeInvalid: "",
      passwordInvalid: "",
      error: null,
    };
  },
  validations: {
    username: {
      required,
      isValidUsername,
    },
    newpassword: {
      required,
      isValidPassword,
    },
    code: {
      required,
    },
  },
  computed: {
    formattedUsername() {
      if (!isNaN(this.username)) {
        return "+" + this.username;
      }
      return this.username;
    },
    isPhoneNumber() {
      const val = this.username;
      return (
        val && (!isNaN(val) || (val.includes("+") && !isNaN(val.substring(1))))
      );
    },
    usernameHint() {
      if (this.isPhoneNumber) {
        return "Phone number must have country code, no spaces or dashes.";
      }
      return "";
    },
  },
  watch: {
    username(val) {
      this.username = val.trim().toLowerCase();
      this.validateUsername();
    },
    code() {
      this.validateVerifyCode();
    },
    newpassword() {
      this.validatePassword();
    },
  },
  methods: {
    formatUsernameForPhone(val) {
      if (this.isPhoneNumber && !val.includes("+")) {
        return val.length <= 10 ? `+1${val}` : `+${val}`;
      }
      return val;
    },
    validateUsername() {
      if (this.$v.username.$invalid) {
        if (!this.$v.username.required) {
          this.usernameInvalid = "Please enter your email or phone number.";
        } else if (!this.$v.username.isValidUsername) {
          this.usernameInvalid = "Please enter a valid email or phone number.";
        }
      } else {
        this.usernameInvalid = "";
      }
    },
    validateVerifyCode() {
      if (this.$v.code.$invalid) {
        if (!this.$v.code.required) {
          this.codeInvalid = "Verification code is required.";
        }
      } else {
        this.codeInvalid = "";
      }
    },
    validatePassword() {
      if (this.$v.newpassword.$invalid) {
        if (!this.$v.newpassword.required) {
          this.passwordInvalid = "Password is required.";
        } else if (!this.$v.newpassword.isValidPassword) {
          this.passwordInvalid =
            "Must be at least 8 characters with uppercase, number and symbol characters.";
        }
      } else {
        this.passwordInvalid = "";
      }
    },
    async submitted() {
      try {
        if (!this.isProcessing) {
          this.isProcessing = true;
          if (this.validate()) {
            await Auth.forgotPasswordSubmit(
              this.formatUsernameForPhone(this.username),
              this.code,
              this.newpassword
            );
            this.resetDone = true;
          }
        }
      } catch (error) {
        this.error = error.message;
      }
      this.isProcessing = false;
    },
    validate() {
      if (this.$v.$invalid) {
        this.validateUsername();
        this.validateVerifyCode();
        this.validatePassword();
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 450px;
  width: 50%;
  flex: 1;
  margin: 0 auto;
  margin-top: 45px;
  .error-message {
    color: grey;
    color: $inqliRed;
    line-height: 24px;
  }
  .message {
    font-weight: bold;
    font-size: 18px;
  }
  .form-item {
    margin-bottom: 0px;
    padding: 0;
  }
  .form-title {
    margin-bottom: 20px;
  }
  .submit-button {
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .form-link {
    display: block;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 90%;
  }
}
</style>
