var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !this.resetDone
      ? _c(
          "form",
          {
            staticClass: "container",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitted.apply(null, arguments)
              }
            }
          },
          [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c("v-text-field", {
                  attrs: {
                    type: "text",
                    label: "Email or Phone number",
                    hint: _vm.usernameHint,
                    "error-messages": _vm.usernameInvalid
                  },
                  model: {
                    value: _vm.username,
                    callback: function($$v) {
                      _vm.username = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "username"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c("v-text-field", {
                  attrs: {
                    type: "text",
                    label: "Verification code",
                    hint: "Enter the code we sent to your email.",
                    "error-messages": _vm.codeInvalid
                  },
                  model: {
                    value: _vm.code,
                    callback: function($$v) {
                      _vm.code = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "code"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-item" },
              [
                _c("v-text-field", {
                  attrs: {
                    name: "password",
                    label: "New password",
                    "append-icon": _vm.showPassword ? "mdi-eye" : "mdi-eye-off",
                    type: _vm.showPassword ? "text" : "password",
                    hint:
                      "At least 8 characters with uppercase, number and symbol characters.",
                    "error-messages": _vm.passwordInvalid,
                    autocorrect: "off",
                    autocapitalize: "off",
                    spellcheck: "false"
                  },
                  on: {
                    "click:append": function($event) {
                      _vm.showPassword = !_vm.showPassword
                    }
                  },
                  model: {
                    value: _vm.newpassword,
                    callback: function($$v) {
                      _vm.newpassword = $$v
                    },
                    expression: "newpassword"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "submit-button" },
              [
                _c(
                  "inqli-button",
                  {
                    attrs: {
                      width: "100%",
                      isProcess: _vm.isProcessing,
                      type: "submit"
                    }
                  },
                  [_vm._v("Submit")]
                )
              ],
              1
            ),
            this.error
              ? _c("div", { staticClass: "error-message" }, [
                  _vm._v("Error: " + _vm._s(this.error))
                ])
              : _vm._e(),
            _c(
              "router-link",
              { staticClass: "form-link", attrs: { to: "/forgot-password" } },
              [_vm._v("Request new code")]
            )
          ],
          1
        )
      : _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "message" }, [
            _vm._v("Password has been reset successfully!")
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-title" }, [
      _c("h1", [_vm._v("Reset password")]),
      _c("p", [
        _vm._v(
          " Check yor email or phone number and fill in the form below to reset your password. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }